.app-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0%;
  bottom: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  animation: app-loader-background-animation 0.8s ease-in;
  z-index: 100;
}

.app-loader-content {
  opacity: 1;
  animation: app-loader-content-animation 0.8s ease-in;
}

@keyframes app-loader-background-animation {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: rgba(255, 255, 255, 0.6);
  }
}

@keyframes app-loader-content-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
